import React, { useEffect } from 'react';
import { MRT_TableInstance } from 'mantine-react-table';
import { RequestTicketDetails } from './gridColumns';
import { onPatch } from 'mobx-state-tree';
import { useStore } from '../../models/ProvideModel';
import { reaction } from 'mobx';

export default function useMantineFiltersListener(table: MRT_TableInstance<RequestTicketDetails>) {
  const store = useStore();
  useEffect(() => {
    if (store.ticket.ticketsGrid.length) {
      const columnFilters = store.ticket.gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, [])
      table.setState(({ ...rest }) => ({
        ...rest,
        columnFilters
      }));
      columnFilters.forEach(({ id }) => {
        const col = table.getColumn(id);
        col.columnDef.enableColumnFilter = false;
      });
    }
    const r = reaction(() => [store.ticket.gridSpecialFilters, store.ticket.columnsVisibility], ([v, _]) => {
      v.forEach(({ id, isActive }) => {
        const col = table.getColumn(id);
        col.columnDef.enableColumnFilter = !isActive;
      });
      // Damn thing doesn't work without a timeout for views with columnOverrides
      setTimeout(() => {
        v.forEach(({ id, isActive }) => {
          const col = table.getColumn(id);
          col.columnDef.enableColumnFilter = !isActive;
        });
      }, 200);
    });
    const p2 = onPatch(store.ticket, ({ op, path }) => {
      if (path === "/currentView" && op === 'replace') {
        table.resetColumnFilters();
        table.resetSorting();
        table.refs.tableContainerRef.current.scrollTo({ top: 0, left: 0, behavior: "auto" });
        table.setColumnVisibility((_) => store.ticket.columnsVisibility);
        const columnFilters = store.ticket.gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, [])
        table.setState(({ ...rest }) => ({
          ...rest,
          columnFilters
        }));
      }
    });

    return () => {
      p2();
      r();
    }
  }, []);
}
