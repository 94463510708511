import React from 'react'
import { SimpleGrid } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { getType } from '../../../../utils/utils';
import { IconFile } from '@tabler/icons-react';
import useStyles from './AttachmentCommentStyles';
import RichTextRenderer from '../../../../components/RichTextOutput';

const isImageType = (fileType) => (getType(fileType) == "jpeg" || getType(fileType) == "jpg" || getType(fileType) == "png")

const AttachmentComment = ({ attachments, customerId, commentMessage }) => {
    const classes = useStyles()
    return (
        <div style={{ display: "flex", flexDirection: 'column', width: "100%" }}>
            {commentMessage ?
                <div style={{ display: "flex", width: "100%" }}>
                    <RichTextRenderer content={commentMessage} />
                </div>
            : null
            }
            <div style={{ display: "flex", width: "100%" }}>
                <SimpleGrid cols={4}>
                    {
                        attachments && attachments.map(file => (
                            <div className={classes.fileDIV}>
                                <a href={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${customerId}&fileId=${file.fileId}&versionId=${file.version || 0}`} target="_blank" >
                                    {isImageType(file.fileType) ?
                                        <img
                                            className={classes.image}
                                            src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${customerId}&fileId=${file.fileId}&versionId=${file.version || 0}`}
                                        /> :
                                        <IconFile className={classes.otherFile} color='#228be6' />}
                                </a>
                                <span className={classes.fileName} >{file.fileName}</span>
                            </div>
                        ))
                    }
                </SimpleGrid>
            </div>
        </div>
    )
}

export default observer(AttachmentComment);