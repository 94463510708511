import React, { memo, useRef, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { IImagePickerProps } from '../ImagePicker';
import { IconX, IconUpload, IconPhoto, IconPhotoOff, IconCirclePlus } from '@tabler/icons-react';
import { Group, Text, Loader } from '@mantine/core';
import { uploadFileV2 } from '../../../api/transactionServer';
import moment from 'moment';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { notifications } from '@mantine/notifications';
import FileItemCard from './FileItemCard';

export interface IDropZoneStyleProps {
  disabled: boolean | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  iconStyles: { width: '1.3em', height: '1.3em', paddingRight: '0.35em', color: '#005eff', },
  iconDivStyles: { display: "flex", justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', '&:hover': { color: '#005eff' } },
  dropozone: (props: { disabled: boolean }) => ({ cursor: props.disabled ? "default" : "pointer", border: props.disabled ? "0.1em solid #ececec" : "0.1em dashed #d9d9d9", '&:hover': { background: props.disabled ? '#fff' : '#f8f9fa' } })
}));

const MAX_SIZE = 25 * 1024 ** 2;

// TODO: Handle localstorage
// TODO: Handle version and history
const MultipleImageField = ({ label, field, dispatch, state, disabled, required, params, onFileChange, usersMap }: IImagePickerProps) => {
  const openRef = useRef<() => void>(null);
  const [fileLoading, setFileLoading] = useState(false);
  const dropZoneStyles: IDropZoneStyleProps = { disabled: disabled }
  const classes = useStyles(dropZoneStyles);
  const isMultiple = params?.multiple || false;

  const onChange = async (files) => {
    // console.log(`Files: ${files} | State: ${state.length} | EditedFile: ${editedFile}`);
    if (!isMultiple) {
      try {
        setFileLoading(true);
        const uploadedFiles = await Promise.all(files.map(file => uploadFileV2({ file })));
        let updatedUploads = uploadedFiles.map(uf => ({ ...uf.data, createdDate: moment() }));
        dispatch({ type: 'SET_UPLOAD', payload: { field, type: 'upload', input: [...updatedUploads] } });
        if(onFileChange) { onFileChange(field); }
      } catch (error) {
        throw new Error(error)
      } finally {
        setFileLoading(false);
      }
      return
    }

    if (files.length && state?.length) {
      try {
        setFileLoading(true);
        const uploadedFiles = await Promise.all(files.map(file => uploadFileV2({ file })));
        let updatedUploads = uploadedFiles.map(uf => ({ ...uf.data, createdDate: moment() }));
        dispatch({ type: 'SET_UPLOAD', payload: { field, type: 'upload', input: [...state, ...updatedUploads] } });
        if(onFileChange) { onFileChange(field); }
      } catch (error) {
        throw new Error(error)
      } finally {
        setFileLoading(false);
      }

    } else if (files.length) {
      try {
        setFileLoading(true);
        const uploadedFiles = await Promise.all(files.map(file => uploadFileV2({ file })));
        let updatedUploads = uploadedFiles.map(uf => ({ ...uf.data, createdDate: moment() }));
        dispatch({ type: 'SET_UPLOAD', payload: { field, type: 'upload', input: updatedUploads } });
        if(onFileChange) { onFileChange(field); }
      } catch (error) {
        throw new Error(error)
      } finally {
        setFileLoading(false);
      }
    }
  };

  return (
    <div>
      <Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text
          weight={500}
          sx={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600, opactity: 0.8 }}
        >{label}
          {required ? (<span style={{ color: "red", marginLeft: "0.25em" }}>*</span>) : null}
        </Text>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {isMultiple ? <span style={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', marginRight: '0.5em', fontWeight: 600, opacity: 0.8 }} onClick={() => { if (openRef?.current) { openRef.current(); } }}><IconCirclePlus style={{ color: '#005eff', cursor: 'pointer' }} width={'1.3em'} height={'1.3em'} /></span> : null}
          <span style={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 400, opacity: 0.8 }}>
            Attachments ({state?.length || 0})</span>
        </div>
      </Group>
      <Dropzone
        openRef={openRef}
        onDrop={(files) => {
          console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE);
          if(!isMultiple && state?.length > 1) return;
          if (files[0].size < MAX_SIZE) onChange(files);
        }}
        onReject={(files) => {
          console.log('rejected files', files);
          notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
        }}
        multiple={isMultiple}
        name={label}
        disabled={disabled}
        activateOnClick={state?.length ? false : true}
        styles={{ inner: { pointerEvents: "all", cursor: disabled ? "default" : "pointer" } }}
        className={classes.dropozone}
        maxSize={MAX_SIZE}
        sx={{padding: (!(state?.length) && !fileLoading) ? '1.5em' : 0 }}
      >
        {state?.length && !fileLoading ?
          (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {state && state.map(file => <div key={file.fileId} style={{ padding: '1em', background: '#f9f9f9', border: '0.01em solid #e9e9e9'}}><FileItemCard file={file} disabled={disabled} field={field} dispatch={dispatch} onFileChange={onFileChange} usersMap={usersMap} /></div>)}
            </div>
          )
          : fileLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loader size={'xs'} />
            </div> : (
              <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline' }}>
                <Dropzone.Accept>
                  <IconUpload
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  {disabled ? <IconPhotoOff size="1.2em" stroke={1.5} /> : <IconPhoto size="1.2em" stroke={1.5} />}
                </Dropzone.Idle>
                <div>
                  <Text size="xs" inline>
                    {disabled ? `No image attached` : `Drag images here or click to select files`}
                  </Text>
                </div>
              </Group>)
        }
      </Dropzone>
    </div >
  );
}

export default memo(MultipleImageField);
