import React from 'react'
import { useHistory } from 'react-router';
import useStyles from './PropertyToolStyles';
import { useStore } from '../../../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import PowerBILinks from '../powerBILinks/PowerBILinks';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import ActivityManagerLink from '../activityManagerLink/ActivityManagerLink';
import { Link } from '@material-ui/core';

const PropertyTool = () => {
  const store = useStore();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    //Get the dashboard items for the user..
    if (store.projectInfo.currentProject &&
      !store.projectInfo.currentProject.loading &&
      // store.projectInfo.currentProject.properties.length && // commented to include powerbi reports (with no projects assigned) even when no are properties assigned to user for a project.
      !store.userReportDetails.powerbiDetails.length &&
      !store.userReportDetails.activityManagerDetails.length &&
      !store.userReportDetails.materialToolDetails.length &&
      !store.userReportDetails.adminToolDetails.length) {
      store.userReportDetails.getUserReportDetails();
    }
  }, []);

  return useObserver(() => (
    <div className={store.responsiveUtils.currentViewport.isLg ? classes.root : classes.rootSm}>
      <div className={classes.propertyTools}>
        <span>Property Tools</span>
      </div>
      <div className={store.responsiveUtils.currentViewport.isLg ? classes.gridContainer : classes.gridContainerSm}>
        {store.userReportDetails?.powerbiDetails?.length > 0 && <PowerBILinks key="powerbi" powerbiDetails={store.userReportDetails.powerbiDetails} classes={classes} />}
        {store.userReportDetails?.activityManagerDetails?.length > 0 && <ActivityManagerLink key="powerbi" activityManagerDetails={store.userReportDetails.activityManagerDetails} classes={classes} />}
        <Link key="snags" id="foo" target="popup"
          href={`${window.location.href.split('/', 3).join('/')}/snags`}
          onClick={() => { document?.getElementById("foo")?.removeAttribute("href"); history.push('/snags'); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
          <span>All Snags</span> <img src={arrowIcon} className={'arrowIcon'} alt={'snags'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
        </Link>
        <Link key="tasks" id="tasks" target="popup"
          href={`${window.location.href.split('/', 3).join('/')}/tasks`}
          onClick={() => { document?.getElementById("tasks")?.removeAttribute("href"); history.push('/tasks'); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
          <span>All Tasks</span> <img src={arrowIcon} className={'arrowIcon'} alt={'snags'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
        </Link>
        <Link key="checklist-reports" id="checklist-reports" target="popup"
          href={`${window.location.href.split('/', 3).join('/')}/checklist-reports`}
          onClick={() => { document?.getElementById("checklist-reports")?.removeAttribute("href"); history.push('/checklist-reports'); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
          <span>Checklist Reports</span> <img src={arrowIcon} className={'arrowIcon'} alt={'Checklist-reports'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
        </Link>
        <Link key="form-data" id="form-data" target="popup"
          href={`${window.location.href.split('/', 3).join('/')}/form-data`}
          onClick={() => { document?.getElementById("form-data")?.removeAttribute("href"); history.push('/form-data'); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
          <span>Form Data Export</span> <img src={arrowIcon} className={'arrowIcon'} alt={'form-data-export'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
        </Link>
        {store.userReportDetails?.materialToolDetails?.length > 0 ?
          store.userReportDetails?.materialToolDetails?.map(dashboard => (
            <Link key={dashboard.provider + dashboard.dashboardName} id={`${dashboard.provider}_${dashboard.dashboardName}`} target="popup"
              href={dashboard.embedConfig?.view ? `/${dashboard.provider}/${dashboard.embedConfig.view}` : `/${dashboard.provider}`}
              onClick={() => { document?.getElementById(`${dashboard.provider}_${dashboard.dashboardName}`)?.removeAttribute("href"); store.ticket.setModuleTitle(dashboard.dashboardName); history.push(dashboard.embedConfig?.view ? `/${dashboard.provider}/${dashboard.embedConfig.view}` : `/${dashboard.provider}`); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
              <span>{dashboard.dashboardName}</span> <img src={arrowIcon} className={'arrowIcon'} alt={`${dashboard.dashboardName} for module ${dashboard.provider}`} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </Link>))
          : null
        }
        {store.userReportDetails?.adminToolDetails?.length > 0 ?
          store.userReportDetails?.adminToolDetails?.map(tool => (
            <Link key={tool.provider + tool.toolName} id={`${tool.provider}_${tool.dashboardName}`} target="popup"
              href={`/${tool.provider}`}
              onClick={() => { document?.getElementById(`${tool.provider}_${tool.dashboardName}`)?.removeAttribute("href"); history.push(`/${tool.provider}`); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
              <span>{tool.dashboardName}</span> <img src={arrowIcon} className={'arrowIcon'} alt={`${tool.dashboardName} for module ${tool.provider}`} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </Link>))
          : null
        }
        {store.userReportDetails?.fbtReportsDetails?.length > 0 ?
          store.userReportDetails?.fbtReportsDetails?.map(report => (
            <Link key={report.provider + report.dashboardName} id={`${report.provider}_${report.dashboardName}`} target="popup"
              href={report.embedConfig?.reportId ? `/${report.provider}/?reportId=${report.embedConfig?.reportId}` : `/${report.provider}`}
              onClick={() => { document?.getElementById(`${report.provider}_${report.dashboardName}`)?.removeAttribute("href"); history.push(`/${report.provider}/?reportId=${report.embedConfig?.reportId}`); return false }} className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
              <span>{report.dashboardName}</span> <img src={arrowIcon} className={'arrowIcon'} alt={`${report.dashboardName} for module ${report.provider}`} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </Link>))
          : null
        }
        {store.userReportDetails?.jsReportDetails?.length > 0 ?
                                                            store.userReportDetails?.jsReportDetails?.map(report => (
                                                              <Link
                                                                key={report.provider + report.dashboardName}
                                                                id={`${report.provider}_${report.dashboardName}`}
                                                                href={`/${report.route}`}
                                                                target="popup"
                                                                onClick={() => { document?.getElementById(`${report.provider}_${report.dashboardName}`)?.removeAttribute("href"); history.push(`/${report.route}`); return false }}
                                                                className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}
                                                              >
                                                                <span>{report.dashboardName}</span> <img src={arrowIcon} className={'arrowIcon'} alt={`${report.dashboardName} for module ${report.provider}`} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
                                                              </Link>))
          : null
}
        {store.userReportDetails?.genericReportsDetails?.length > 0 ?
                                                                  store.userReportDetails?.genericReportsDetails?.map(report => (
                                                                    <Link
                                                                      key={report.provider + report.dashboardName}
                                                                      id={`${report.provider}_${report.dashboardName}`}
                                                                      href={`/${report.route}`}
              target="popup"
              onClick={() => { document?.getElementById(`${report.provider}_${report.dashboardName}`)?.removeAttribute("href"); history.push(`/${report.route}`); return false }}
              className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}
            >
              <span>{report.dashboardName}</span> <img src={arrowIcon} className={'arrowIcon'} alt={`${report.dashboardName} for module ${report.provider}`} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </Link>))
          : null
        }
        {/* <div key="units" onClick={() => { history.push('/unit-act-info'); }} className={`${classes.tools} ${classes.underlineAnim}`}>
            <span>All Units</span> <img src={arrowIcon} className={'arrowIcon'} alt={'units'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </div> */}
      </div>
    </div>
  ))
}

export default PropertyTool
