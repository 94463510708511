import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  mainDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexBasis: "auto",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "100%",
    overflow: "hidden"
  },
  handleContainer: (props: { isLg: boolean }) => ({
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
    flexShrink: 1,
  }),
  taskListDiv: {
    display: 'flex',
    flexDirection: 'column',
    /* justifyContent: 'center', */
    // alignItems: 'center',
    width: '100%',
    position: "sticky",
    top: 0,
    // background: "#D0EBFF",
    // background: "#E7F5FF",
    background: '#F1F3F5',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    zIndex: 1,
    boxShadow: "0px 10px 10px -5px rgba(0,0,0,0.12)",
    /* maxHeight: '6em', */
    overflow: 'auto',
    maxHeight: "30%"
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    padding: '0.2em 1.5em'
  },
  taskListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `0.0525em solid #D9D9D9`,
    // borderRadius: '0.35em',
    width: '100%',
    margin: '0.25em 0em',
    padding: '0.3em',
    fontSize: '0.825em',
    background: '#FFF',
    "&:hover": {
      cursor: 'pointer',
      boxShadow: "0px 5px 10px -5px rgba(0,0,0,0.12)",
      // border: `0.0525em solid #005EFF`, 
    }
  },
  taskTitleDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0.5em 1.5em',
    position: "sticky",
    top: 0,
    // background: "#E7F5FF",
    background: "#D0EBFF",
    zIndex: 1,
    boxShadow: "0px 10px 10px -5px rgba(0,0,0,0.12)"
  },
  taskTitle: {
    fontSize: "1.025em",
    fontWeight: 600,
    color: '#585858',
    textDecoration: 'uppercase',
    padding: '0.25em'
  },
  listArrow: {
    transform: 'rotate(270deg)'
  },
  handleScrollLayer: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 1,
    flexGrow: 1,
    overflow: "auto",
    width: "100%",
    alignItems: "center",
    /* marginBottom: '15%', */
    padding: '0em 1.5em',
    // [theme.breakpoints.up('md')]: {
    //     height: '100%'
    // }
  },
  fieldData: ({ isLg }: { isLg: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    marginBottom: "8px",
    // paddingTop: "1em",
    maxWidth: isLg ? 800 : "80%",
    flexGrow: 1,
    width: "100%",
  }),
  hiddenInput: {
    display: "flex",
    justifyContent: "flex-start",
    color: "#000",
    transform: "translateX(-100%)",
    padding: '1em',
  },
  visibleInput: {
    transform: "translateX(0%)"
  },
  captionText: {
    fontSize: "0.8em",
    opacity: 0.6
  },
  submitCancelDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    textAlign: "center",
    width: "100%",
    paddingBottom: '0.5em'
  },
  submitbutton: {
    display: "flex",
    flexGrow: 1,
    fontSize: "0.7em",
    color: "white",
    background: " #005eff",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: 600,
    maxWidth: "20%",
    height: "33px",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#1a6fef",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  },
}))

export default useStyles;
