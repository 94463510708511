import { makeStyles, Theme } from "@material-ui/core/styles";
import { TicketStatusCategories } from "../../../models/enums";
import { materialStatusColours, requestPrioritiesColors } from "../../../utils/constants";

export interface IStatusStyle {
  status: TicketStatusCategories;
  hideCoreFields: boolean;
}

const useStyles = makeStyles<Theme, IStatusStyle>((theme: Theme) => ({
  root: props => ({
    display: "flex",
    width: "100%",
    flexBasis: "auto",
    background: "#FFF",
    padding: '0.15em 1em',
    // marginBottom: '1em',
    flexDirection: 'column'
  }),
  main: props => ({
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap'
    // flexWrap: 'nowrap',
  }),
  coreItem: props => ({
    flexBasis: 'calc(33.33% - 10px)',
    marginBottom: '0.5em',
    padding: '0.45em',
    // display: 'flex',
    // width: '100%'
  }),
  coreItemBasis: {
    // flexBasis: 'calc(33.33% - 10px)',
    marginBottom: '0.5em',
    padding: '0.45em',
  },
  key: {
    fontSize: "0.7em",
    color: '#585858',
    opacity: 0.8,
    fontWeight: 600
  },
  value: {
    fontSize: "0.92em",
    color: '#585858',
    marginTop: 4
  },
  status: props => ({
    width: '0.8em',
    height: '0.8em',
    display: "inline-block",
    backgroundColor: materialStatusColours[props.status]?.bg || "white",
    borderRadius: "50%",
    border: `1px solid ${materialStatusColours[props.status]?.border || "white"}`,
  }),
  coreFieldDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  showHideAction: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.7em',
    cursor: 'pointer',
    background: "#FFF",
    border: "0.01em solid #E9E9E9",
    padding: 0,
  },
  arrow: props => ({
    width: '1.5em',
    transform: props.hideCoreFields ? 'rotate(0deg)' : 'rotate(180deg)'
  }),
  gridItem: {
    padding: '0.5em !important'
  },
  container: props => ({
    display: 'flex',
    alignItems: 'center',  // Vertical alignment of icon and text
    // cursor: 'pointer',
    padding: '0px 12px',   // Add padding for better spacing
    borderRadius: '6px',   // Rounded corners
    fontSize: '12px',      // Set font size
    // backgroundColor: '#f5f5f5', // Light background for visibility
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
    transition: 'background-color 0.3s ease', // Smooth hover effect
  }),
  icon: {
    marginRight: '8px',    // Space between icon and text
    fontSize: '1.2em',     // Slightly larger icon size
    display: 'flex',
    alignItems: 'center',  // Vertically align icon content
  },
  child: {
    fontSize: '1em',       // Set font size for child content
    color: '#333',         // Set text color for better readability
  },
  statusBg: props => ({
    // backgroundColor: '#ffffff',
    border: `1px solid ${materialStatusColours[props.status]?.border || "#f5f5f5"}`,
  }),
  prioBg: props => ({
    // backgroundColor: '#ffffff',
    border: `1px solid #f5f5f5`,
  }),
  seeMore: props => ({
    display: 'flex', 
    // alignSelf: "center", 
    alignItems: 'center',
    cursor: 'pointer',
    opacity: .8,
    marginLeft: '0.25em',
    '&:hover': {
      opacity: 1
      // fontWeight: 600,
      // scale: 1
    }
  })
}));

export default useStyles;
