import React, { memo } from 'react';
import { Title, Divider } from '@mantine/core';
import { IGenericInputProps } from './GenericInput';

const Headline = memo(({ label, ...rest }: IGenericInputProps) => (
  // <div>
  //   {rest.type === "headline-with-line" ?
  //    <Divider
  //      label={<Title order={2} size="h4"> {label} </Title>}
  //      labelPosition="center"
  //      size="sm" />
  //   : <Title order={2} size="h4">
  //     {label}
  //    </Title>
  //   }
  // </div>
  
  <div style={{display: 'flex', fontSize: '1.15em', fontWeight: 600, backgroundColor: '#FAF8FF', padding: '0.8em', margin: '1em 0em', borderRadius: '0.25em'}}>
    {label}
  </div>

  ));

export default Headline;
