import { types, flow, getRoot, getSnapshot, Instance } from 'mobx-state-tree';
// import { Instance } from 'mobx-state-tree/dist/internal';
import { LateStoreModel } from './DataStore';
import { GET_USER_GROUP_REPORT_DETAILS } from '../utils/queries';

// type defs
const powerBiConfigModel = types.model({
    group_id: types.string,
    report_id: types.string
})

const userReports = types.model({
    dashboardName: types.string,
    embedConfig: powerBiConfigModel,
    provider: types.string,
    group: types.array(types.string),
})

const activityManagerConfigModel = types.model({
    redirectUrl: types.string
})

const activityManagerReport = types.model({
    dashboardName: types.string,
    embedConfig: activityManagerConfigModel,
    provider: types.string,
    group: types.array(types.string),
})

const materialHandlingTool = types.model({
    dashboardName: types.string,
    provider: types.string,
    group: types.array(types.string),
    embedConfig: types.maybeNull(types.frozen<{ view?: string; }>()),
});

const adminTool = types.model({
    dashboardName: types.string,
    embedConfig: types.maybeNull(types.frozen<{ version?: string; }>()),
    provider: types.string,
    group: types.array(types.string),
})

const fbtReports = types.model({
    dashboardName: types.string,
    embedConfig: types.maybeNull(types.frozen<{ reportId: string; reportName: string | undefined }>()),
    provider: types.string,
    group: types.array(types.string),
})

const genericReport = types.model({
    dashboardName: types.string,
    embedConfig: types.maybeNull(types.frozen<{ [K: string]: any; }>()),
    provider: types.string,
    group: types.array(types.string),
}).views(self => ({
    get route() {
        return self.embedConfig?.url || self.provider;
    }
}));

export interface IJsReportDashboard {
    templateId: string;
    data?: { [K: string]: any; };
    type?: string;
};

const jsReport = types.model({
    templateId: types.identifier,
    dashboardName: types.string,
    embedConfig: types.frozen<IJsReportDashboard>(),
    provider: types.string,
    group: types.array(types.string),
}).views(self => ({
    get route() {
        return `js-report/${self.templateId}`;
    },
    get data() {
        const root = getRoot(self) as Instance<typeof LateStoreModel>;
        const userId = root.auth.userId;
        const userName = root.auth.userName;
        const customerId = root.auth.customerId;
        const customerName = root.auth.customerName;
        const projectId = root.projectInfo.currentProject?.id;
        const projectName = root.projectInfo.currentProject?.name;
        const properties = root.projectInfo.currentProject?.properties;
        const builderImage = root.projectInfo.currentProject?.builder_image;
        const projectImage = root.projectInfo.currentProject?.project_image;
        const email = root.auth.email;
        return { ...(self.embedConfig.data || {}), sessionInfo: { userId, userName, customerId, customerName, email, builderImage, properties, projectImage, projectId, projectName, } };
    },
}));

const UserReportDetails = types.model({
    powerbiDetails: types.array(userReports),
    activityManagerDetails: types.array(activityManagerReport),
    materialToolDetails: types.array(materialHandlingTool),
    adminToolDetails: types.array(adminTool),
    fbtReportsDetails: types.array(fbtReports),
    genericReportsDetails: types.array(genericReport),
    jsReportDetailsMap: types.map(jsReport),
}).volatile(_ => ({ loading: false }))
    .views(self => ({
        get shouldRenderPropTool(): boolean {
            return !!self.powerbiDetails || !!self.activityManagerDetails || !!self.materialToolDetails || !!self.adminToolDetails || !!self.fbtReportsDetails || !!self.genericReportsDetails || !!self.jsReportDetailsMap;
        },
        get jsReportDetails() {
            return Array.from(self.jsReportDetailsMap.values());
        },
    }))
    .actions(self => ({
        clearUserReportDetails() { self.powerbiDetails.clear(); self.activityManagerDetails.clear(); self.materialToolDetails.clear(); self.adminToolDetails.clear(); self.fbtReportsDetails.clear(); self.genericReportsDetails.clear(); self.jsReportDetailsMap.clear(); },
        getUserReportDetails: flow(function* populate() {

            self.loading = true;
            try {
                const root = getRoot(self) as Instance<typeof LateStoreModel>;
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_USER_GROUP_REPORT_DETAILS, {
                    variables: { project: root.projectInfo.currentProject?.id },
                    fetchPolicy: 'network-only'
                });
                data?.reports?.forEach(({ provider, dashboardName, embedConfig, group }) => {
                    if (provider === "power-bi")
                        self.powerbiDetails.push({ provider, dashboardName, embedConfig, group })
                    else if (provider === "activity-manager")
                        self.activityManagerDetails.push({ provider, dashboardName, embedConfig, group })
                    else if (provider === "requests") {
                        self.materialToolDetails.push({ provider, dashboardName, group, embedConfig });
                        (getRoot(self) as Instance<typeof LateStoreModel>).ticket.setModuleTitle(dashboardName);
                    }
                    else if (provider === "admin-tools")
                        self.adminToolDetails.push({ provider, dashboardName, embedConfig, group })
                    else if (provider === "fbt-reports")
                        self.fbtReportsDetails.push({ provider, dashboardName, embedConfig, group })
                    else if (provider === "js-report" || (embedConfig?.type === "js-report")) {
                        self.jsReportDetailsMap.set(embedConfig!.templateId, { templateId: embedConfig!.templateId, provider, dashboardName, embedConfig, group });
                    }
                    else {
                        self.genericReportsDetails.push({ provider, dashboardName, embedConfig, group });
                    }
                })
            } catch (err) {
                console.error(err.message);
            }
            finally {
                self.loading = false;
            }
        })
    }))
    .actions(self => ({
        getFbtCustomReportName(reportId: string) {
            if (self.fbtReportsDetails.length) {
                const report = self.fbtReportsDetails.find(report => {
                    if (report.embedConfig && typeof report.embedConfig === 'object') {
                        const config = JSON.parse(JSON.stringify(report.embedConfig));
                        return config.reportId === reportId;
                    }
                    return false;
                });
                if (report && report.embedConfig) {
                    const config = JSON.parse(JSON.stringify(report.embedConfig));
                    return config.reportName || "";
                }
            }
            return "";
        }
    }))

// *exports
export default UserReportDetails;
export interface IJsReportDetails extends Instance<typeof jsReport> { };

export type IUserReportDetails = typeof UserReportDetails.Type;
