import React from 'react';
import { DataSheetGrid, Column } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  gutterMainRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    // padding: '10px',
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #ddd',
    height: '100%',
    boxSizing: 'border-box',
      width: '100%',
  },
  sideA: {
    minWidth: '40px',
    fontWeight: 600,
    fontSize: '1.2em',
    color: '#005eff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #ddd',
    marginRight: '10px',
    height: '100%'
  },
  sideB: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '2px', // Space between rows
  },
  labels: {
    color: '#333',
    fontWeight: 600,
    fontSize: '0.9em',
  },
  data: {
    color: '#555',
    fontWeight: 400,
    fontSize: '0.9em',
  },
}))

interface IDataGridProps<T> {
  data: T[];
  columns: Column<T>[];
  onChange?: (updatedData: T[]) => void;
}

const DataGrid = <T,>({ data, columns, onChange }: IDataGridProps<T>) => {
  const classes = useStyles();
  return (
    <DataSheetGrid
      value={data}
      onChange={onChange}
      columns={columns}
      // height={450}
      style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}
      height={1085}
      rowHeight={145}
      lockRows
      headerRowHeight={35}
      gutterColumn={{
        basis: 400,
        component: ({ rowData, rowIndex }) => {
          return (
            <div className={classes.gutterMainRoot}>
            <div className={classes.sideA}>{rowIndex + 1}</div>
            <div className={classes.sideB}>
              <div>
                <span className={classes.labels}>Unit:</span>{' '}
                <span className={classes.data}>{rowData['unitTitle'] || '-'}</span>
              </div>
              <div>
                <span className={classes.labels}>Activity:</span>{' '}
                <span className={classes.data}>{rowData['activity'] || '-'}</span>
              </div>
              <div>
                <span className={classes.labels}>Planned start:</span>{' '}
                <span className={classes.data}>{rowData['plannedStart'] || '-'}</span>
              </div>
              <div>
                <span className={classes.labels}>Planned end:</span>{' '}
                <span className={classes.data}>{rowData['plannedEnd'] || '-'}</span>
              </div>
              <div>
                <span className={classes.labels}>Total QTY:</span>{' '}
                <span className={classes.data}>
                  {rowData['plannedTotalQty'] || '-'}{' '}
                  {rowData['plannedTotalQty'] ? rowData['unitOfMeasure'] : ''}
                </span>
              </div>
              <div>
                <span className={classes.labels}>Month's target:</span>{' '}
                <span className={classes.data}>
                  {rowData['monthsTarget'] || '-'}{' '}
                  {rowData['monthsTarget'] ? rowData['unitOfMeasure'] : ''}
                </span>
              </div>
            </div>
          </div>
          )
        },
        // title: () => <div style={{display: 'flex'}}>Unit Activity details</div>,
        title: 'Unit Activity details'
      }}
    />
  );
};

export default DataGrid;
