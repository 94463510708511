import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, CloseButton, Group, Popover, Tooltip } from '@mantine/core';
import { getInitials } from "../../utils/utils";
import { ITask, ITicket, IUser } from "../../models/Ticket";
import { IconPencil } from "@tabler/icons-react";
import GenericModal from "../../containers/GenericModal";
import AssigneeList from "./AssigneeList";
import { IChan, putAsync } from "csp-with-ts";
import { notifications } from "@mantine/notifications";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  avatarSpan: {
    display: 'flex'
  },
  iconStyle: {
    width: '1.25em',
    height: '1.25em'
  },
  avatarStyle: {
    // width: '1.5em',
    // height : '1.5em',
    '&:not(:first-child)': {
      // marginLeft : '-10px'
    }
  },
  editIcon: {
    borderRadius: '50%', 
    border: '2px dotted #d9d9d9', 
    display: 'inline-flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    padding: '0.3em', 
    cursor: 'pointer',
    '&:hover' : {
      background: '#f9f9f9'
    }
  }
}));

const Assignees = ({ assignees, userId, ticket, ch }: { assignees: IUser[]; userId: string, ticket: ITicket | undefined, ch: IChan; }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const closeAssigneeListModal = () => setOpen(false);
  const handleAssigneeUser = (userId: string) => {
    putAsync(ch, { field: 'assignee', value: userId })
    setOpen(false);
    notifications.show({
      title: 'Assignee changed',
      message: ticket ? `Ticket assigned to ${ticket.processVariables.usersMap[userId]}` : `Ticket assigned to new user.` 
    })
  };
  
  return (
    <div className={classes.root}>
      {(assignees.length && assignees[0].id !== null) ?
        <span className={classes.avatarSpan}>
          {assignees.map((u) => <Tooltip key={u.id} label={u.name}>
            <Avatar className={classes.avatarStyle} size={'sm'} color="cyan" radius="xl">{
              getInitials(u.name)
            }</Avatar>
          </Tooltip>)}
            <span style={{ display: 'flex', alignSelf: 'center', }}>
              {ticket && ticket.ticketDefinition.isAdhoc ?
                <Group position="center">
                  <Popover position="bottom" withArrow shadow="md" opened={open} onClose={closeAssigneeListModal}>
                    <Popover.Target>
                    <Tooltip position='right-end' label={"Re-Assign"}>
                      <div onClick={() => { setOpen(true); }} className={classes.editIcon}>
                        <IconPencil
                          style={{ width: '1em', height: '1em', cursor: 'pointer' }} 
                        />
                      </div>
                    </Tooltip>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <AssigneeList
                        userId={ticket && ticket.currentTask?.assignee && ticket.currentTask?.assignee[0]}
                        handleAssigneeUser={(userId: string) => { handleAssigneeUser(userId); setOpen(false); }}
                        options={ticket && ticket.ticketDefinition.features.editableAssignee && ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[0]] && ticket.processVariables[ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[0]][ticket.currentTask?.taskDefinitionKey || ""] || ""]?.map(id => ({ id, name: ticket.processVariables.usersMap[id] ? ticket.processVariables.usersMap[id] : id })) || []} />
                    </Popover.Dropdown>
                  </Popover>
                </Group>
                : null
              }
              </span>
          </span>
          :
        <Avatar size={'sm'} style={{ color: '#D9D9D9' }} radius="xl"></Avatar>
      }
    </div >
  )
};

export default observer(Assignees);
