import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getJsReport } from '../../api/transactionServer';
import { IJsReportDetails } from '../../models/UserReportDetails';
import { useStore } from '../../models/ProvideModel';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import errorImg from '../../assets/images/brwsrSupprt/errorImage.png';

const quotes = [
  "A goal without a plan is just a wish",
  "Failing to plan is planning to fail",
  `“A good plan today is better than a perfect plan tomorrow.” – George S. Patton`,
  `“Plans are of little importance, but planning is essential.” – Winston Churchill`,
  `“Give me six hours to chop down a tree and I will spend the first four sharpening the axe.” – Abraham Lincoln`,
  `“Someone’s sitting in the shade today because someone planted a tree a long time ago.” – Warren Buffett`
]

const getRandomIndex = () => Math.floor(Math.random() * quotes.length)

/* height={store.responsiveUtils.currentViewport.isLg ? "" : 95} width={store.responsiveUtils.currentViewport.isLg ? "" : 74} */
const ErrorComp = ({ errorMessage }: { errorMessage: string; }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 450 }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={errorImg}
      />
      <span style={{ fontSize: '1em', fontStyle: 'italic' }}>{quotes[getRandomIndex()]}</span>
      <span style={{ fontSize: '0.8em', }}>Could Not Fetch Report.</span>
      <span style={{ fontSize: '1.2em', color: "red" }}>{errorMessage}</span>
    </div>
  </div>
);


/*
   Consider moving to Blob URLs if performance is impacted in embedded reports
 */

const EmbeddedJsReportPage = observer(({ match: { params: { templateId } } }: RouteComponentProps<{ templateId: string; }>) => {
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState<string>("");
  const [error, setError] = useState<string>("");
  const store = useStore();
  useEffect(() => {
    const report: IJsReportDetails | undefined = store.userReportDetails.jsReportDetailsMap.get(templateId);
    var prom = Promise.resolve(report);
    if (store.projectInfo.currentProject) {
      if (!store.userReportDetails.jsReportDetailsMap.has(templateId)) {
        prom = store.userReportDetails.getUserReportDetails().then(() => store.userReportDetails.jsReportDetailsMap.get(templateId));
      }
      setLoading(() => true);
      prom
        .then((rep) => {
          if (rep) {
            /* return getJsReport({ templateId, data: rep.data, }); */
            return getJsReport({ templateId, data: rep.data });
          }
          throw new Error("No such report is configured for you.");
        })
        .then(({ data }) => {
          setHtml(() => data);
          setLoading(false);
        })
        .catch((err) => { setLoading(false); setError(err.message || ""); });
    }
  }, [templateId]);

  return (<div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', padding: '0.5em 1em', position: "sticky", top: 0, }}>
      <h3 style={{ margin: 0 }}>{store.userReportDetails.jsReportDetailsMap.get(templateId)?.dashboardName || "Embedded Report"}</h3>
    </div>
    <div style={{ display: "flex", width: "100%", flexGrow: 1 }}>
      {loading ?
        (
          <LoadingSpinner />
        ) :
        html.length ?
          (
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <iframe srcDoc={html}
                style={{ width: "100%", height: "100%" }}
              >
              </iframe>
            </div>
          )
          :
          (
            <div style={{ display: "flex", placeContent: "center", alignSelf: "center", width: "100%" }}>
              <ErrorComp errorMessage={error} />
            </div>
          )
      }
    </div>
  </div>);
});

export default EmbeddedJsReportPage;
