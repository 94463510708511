import React from 'react';
import { Popover, Tooltip } from '@mantine/core';
import { useState } from 'react';

const GenericPopover = ({ target, dropdown, label, popoverProps = {}, disableTooltip = false, disableDropdown = false }) => {
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="bottom"
      withArrow
      {...popoverProps}  // Spread additional Popover props if needed
    >
      {/* Dynamic Target */}
      {
        disableTooltip ? 
        <Popover.Target>
        <div onClick={() => setOpened((o) => !o)} style={{height: '20px'}}>
          {target}
        </div>
      </Popover.Target>
      :
      <Tooltip label={label}>
      <Popover.Target>
        <div onClick={() => setOpened((o) => !o)} style={{height: '20px'}}>
          {target}
        </div>
      </Popover.Target>
      </Tooltip>

      }
      {/* Dynamic Dropdown */}
      {!disableDropdown && (
        <Popover.Dropdown>
          {React.cloneElement(dropdown, {
            setAutoFocus: opened,
            closePopover: () => setOpened(false), // Pass closePopover to dropdown
          })}
        </Popover.Dropdown>
      )}
      
    </Popover>
  );
};

export default GenericPopover;
