import React, { memo, FunctionComponent, useEffect, useState, SetStateAction, Dispatch, useMemo } from "react";
import { SnapshotOut } from "mobx-state-tree";
import { Redirect, useLocation, useHistory } from "react-router";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { useStore } from "../models/ProvideModel";
import UrlToMST from "./UrlToMst";
import Header from "../containers/header/Header";
import InfoPanel from "../components/infoPanel/InfoPanel";
import useStyles from "./MainStyles";
import { eGraphType, eScreen, eSection } from "../models/Common";
import LoadingSpinner from "../components/loadingSkelaton/LoadingSpinner";
import jwt_decode from "jwt-decode";
import { Desktop, TabletOrMobileBoth } from "../utils/responsive";
import InfoPanelSm from "../components/infoPanelSm/InfoPanelSm";
import { CircularButton } from "../components/circularButton/CircularButton";
import filterIcon from "../assets/images/FilterSmall.svg";
import { useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../components/modal";
import InfoPanelWidgets from "../components/InfoPanelWidgets/InfoPanelWidgets";
import SortSmall from "../assets/images/Sort - Small.svg";
import CloseDefault from '../assets/images/menuNavigation/Close Default.svg'
import FullScreenIcon from "../assets/images/mobileIcons/FullScreenIcon.svg"
import { reaction } from "mobx";
import { useMediaQuery } from "react-responsive";
import { LG_DEVICE_QUERY, SM_LANDSCAPE_QUERY, XS_LANDSCAPE_QUERY } from "../utils/responsive";
import { notifications } from "@mantine/notifications";

const timerFn = (fn: Dispatch<SetStateAction<boolean>>) => { fn(false); };

// moving these here because they're static throughout app lifetime, thus need
// to only be evaluated on file load

/* const notAllowedPaths = ['/menu']; */
const notAllowedSections = ['home'];
const showToggleFullScreenBtn = (currentSection = ''): boolean => {
  return !notAllowedSections.includes(currentSection);
}

export interface PrivateRouteProps extends RouteProps {
  component: FunctionComponent<RouteComponentProps<any>>;
  screen?: SnapshotOut<typeof eScreen>;
  section: SnapshotOut<typeof eSection>;
  graphType?: SnapshotOut<typeof eGraphType>;
  scaffold?: boolean;
  infoPanel?: boolean;
  spaceType?: string;
}
/* Convert closure returning JSX to React Component
   Gets rid of the need to recompute everytime the parent rerenders.
 */
const LoadBodyJsx = memo(({ classes, screen, component: Component, ...rest }: PrivateRouteProps & { classes: any; }) => {
  return (
    <div id="demo" className={classes.windowPane}>
      <Route
        {...rest}
        render={(props) => (
          <UrlToMST screen={screen} {...rest}>
            <Component {...props} />
          </UrlToMST>
        )}
      />
    </div>
  );
});


const PrivateRoute = memo(
  ({
    component: Component,
    screen,
    scaffold,
    infoPanel,
    ...rest
  }: PrivateRouteProps) => {
    // let { component: Component, ...rest } = props;
    const store = useStore();
    const isLg = useMediaQuery(LG_DEVICE_QUERY);
    const isSm = useMediaQuery(SM_LANDSCAPE_QUERY);
    const isXs = useMediaQuery(XS_LANDSCAPE_QUERY);
    const history = useHistory();
    useEffect(() => {
      // screen calculations and info panel
      store.responsiveUtils.currentViewport.setLg(isLg);
      store.infoPanel.setInfoPanel();
      store.responsiveUtils.currentViewport.setSm(isSm);
      store.responsiveUtils.currentViewport.setXs(isXs);
      /* ----
         Session expiry tracking
       */
      const logoutOnTokenExpiry = (function () {
        if (store.auth.isAuthenticated && store.auth.expiry) {
          const dateDiff = (store.auth.expiry * 1000 - Date.now());
          if (dateDiff < 0) {
            store.auth.logout();
            notifications.show({ title: 'Session Expired!', message: 'You have been logged out because your session expired.' });
            history.push("/login");
          }
          else {
            const timer = setTimeout(() => {
              store.auth.logout();
              notifications.show({ title: 'Session Expired!', message: 'You have been logged out because your session expired.' });
              history.push("/login");
            }, dateDiff);
            return () => { clearTimeout(timer); };
          }
        }
        return () => { };
      })();

      const fullScreenListener = reaction(() => store.responsiveUtils.fullScreenMode,
        (_) => { setShowFilterBtn(store.responsiveUtils.fullScreenMode); })
      return () => {
        logoutOnTokenExpiry();
        fullScreenListener();
      }
    }, []);
    const flag = scaffold || false;
    const infoPanelflag = infoPanel || false;
    const params = store.params;
    const { fullScreenMode } = store.responsiveUtils;
    const infopanel = store.filters.infopanelOpen
    const classes = useStyles({ params, fullScreenMode, infopanel });
    const [spin, setSpin] = useState<boolean>(false);
    const location = useLocation();
    const [lastLocation, setLocation] = useState<URLSearchParams>(() => new URLSearchParams(location.search));
    const printFlag = useMemo(() => new URLSearchParams(location.search).get('view') === 'print', [location.search]);
    const menuFlag = useMemo(() => new URLSearchParams(location.search).get('popup_menu') === 'true', [location.search]);
    const [showFilterBtn, setShowFilterBtn] = useState(false);
    const [filterModalState, setFilterModalState] = React.useState(false);

    useEffect(() => {
      var timer;
      const lastMenuFlag = lastLocation.get('popup_menu') === 'true';
      if (lastMenuFlag !== menuFlag) { setLocation(new URLSearchParams(location.search)); }
      else if (lastLocation.toString() !== location.search.slice(1)) {
        setSpin(true);
        timer = setTimeout(timerFn, 400, setSpin);
      }
      return () => {
        if (timer) { clearTimeout(timer); }
      };
    }, [location.search, location, menuFlag, lastLocation]);

    // check for token expire - have to have better ux
    // triggerd only on page refresh or url hit
    /*
       useEffect(() => {
       let token = localStorage.getItem("Authorization") || null;
       if (!!token) {
       let decoded = jwt_decode(token);
       const dateNow = Math.round(new Date().getTime() / 1000);
       if (decoded.exp < dateNow) {
       store.auth.logout();
       }
       }

       }, []);
     */

    const { isOpen } = useModal({ isOpen: filterModalState, closeOnOverlayClick: true });

    const onFilterModalClose = () => {
      setFilterModalState(!filterModalState);
    }

    const onFilterBtnClicked = () => {
      setFilterModalState(true);
    }

    /*
       useEffect(() => reaction(() => store.responsiveUtils.fullScreenMode,
       (_) => { setShowFilterBtn(store.responsiveUtils.fullScreenMode); })
       , []);
     */

    const onFullScreenClicked = () => {
      store.responsiveUtils.toggleFullscreenMode();
    }

    const showHeader = (store.params.section !== "home" && store.responsiveUtils.fullScreenMode === false);
    const filterApplied = (location) => {
      return location.search !== '';
    }

    const isFilterVisible = () => {
      return showFilterBtn && store.params.section !== 'home';
    }

    const closeModel = () => {
      setFilterModalState(false);
    }

    return useObserver(() =>
      store.auth.isAuthenticated ?
        (!store.projectInfo.currentProject && location.pathname !== '/projects') ? (<Redirect to="/projects" />)
          :
          (
            flag ? (
              <div className={(isXs || isSm) ? classes.rootSm : classes.root}>
                {showHeader && <Header />}
                <div className={classes.mainWindow}>
                  {infoPanelflag && !printFlag ? (
                    <>
                      <Desktop>
                        <div className={store.filters.infopanelOpen ? classes.closeinfopanel : classes.filterPanel}>
                          {store.filters.infopanelOpen ? <InfoPanelSm /> : <InfoPanel />}
                        </div>
                      </Desktop>
                      <TabletOrMobileBoth>
                        {
                          store.responsiveUtils.fullScreenMode === false && (
                            <div className={classes.filterPanelSm}>
                              <InfoPanelSm />
                            </div>
                          )
                        }
                      </TabletOrMobileBoth>
                      {!spin ?
                        <LoadBodyJsx
                          classes={classes}
                          screen={screen}
                          component={Component}
                          {...rest} />
                        : (
                          <div
                            style={{
                              background: "#fff",
                              width: !isLg ? "100%" : store.filters.infopanelOpen ? "95%" : "80.5%",
                              height: "99vh",
                              // zIndex: 3,
                              // opacity: store.responsiveUtils.fullScreenMode === false ?  0.7 : 1
                            }}
                          >
                            <LoadingSpinner />
                          </div>
                        )}
                    </>
                  )
                    :
                    <LoadBodyJsx
                      classes={classes}
                      screen={screen}
                      component={Component}
                      {...rest} />
                  }
                </div>
                {isLg &&
                  store.infoPanel.panelsListDivided[3] &&
                  store.infoPanel.panelsListDivided[3].map(
                    ({ component: Comp, field, props }) => (
                      // <div key={field} className={classes.filtersPadding}>
                      <Comp key={field} {...props} />
                      // </div>
                    )
                  )
                  // (store.params.section !== "home") && ((store.params.graphType === "table" || store.params.graphType === "powerbi") ? null : <Footer />)
                }
                <TabletOrMobileBoth>
                  {(isFilterVisible() && store.params.graphType !== "powerbi" && store.params.screen !== "misc") && (
                    <div>
                      <div className={classes.filterIconBtn}>
                        <CircularButton floating onClick={onFilterBtnClicked} size="filterIconBtn">
                          {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                          <img src={filterIcon} alt="filter" style={{ height: "13px" }} />
                          {filterApplied(location) && (<span className={classes.filterIndicator}></span>)}
                          {/* </div> */}
                        </CircularButton>
                      </div>
                      <Modal
                        isOpen={isOpen}
                        isCentered={false}
                        onClose={onFilterModalClose}
                        closeOnOverlayClick={true}
                        position={{ top: 20, left: 20 }}
                      >
                        <ModalOverlay>
                          <div className={classes.mainDiv}>
                            <div className={classes.changeImage} onClick={closeModel}>
                              <img src={CloseDefault}></img>
                            </div>
                            <ModalContent>
                              <ModalBody style={{ padding: "15px 5px" }}>
                                <InfoPanelWidgets />
                              </ModalBody>
                            </ModalContent>
                          </div>
                        </ModalOverlay>
                      </Modal>
                    </div>
                  )}
                  {showToggleFullScreenBtn(store.params.section) && (
                    <div className={classes.fullscreenBtn}>
                      <CircularButton floating onClick={onFullScreenClicked} size="fullscreenBtn">
                        <img className={classes.fullScrIco} src={!store.responsiveUtils.fullScreenMode ? SortSmall : FullScreenIcon} alt="sort Small" />
                      </CircularButton>
                    </div>
                  )}
                </TabletOrMobileBoth>
              </div>
            ) : (

              <Route
                {...rest}
                render={(props) => (
                  <UrlToMST screen={screen} {...rest}>
                    <Component {...props} />
                  </UrlToMST>
                )}
              />
            )
          ) : (
          <Redirect to="/login" />
        )
    );
  }
);

export default PrivateRoute;
