import React, { PropsWithChildren, memo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DialogProps } from '@material-ui/core/Dialog';
import LoadingSpinner from '../components/loadingSkelaton/LoadingSpinner';
import LoadingTowers from '../components/LoadingTowers'

// Later, refactor the two shares of email and whatsapp into two files with this
// file being the index.
export interface IGenericPopup {
  isOpen: boolean;
  title?: string;
  onClose(): void;
  actions: { type: string; onClick(): void; disabled?: boolean; }[];
  maxWidth?: DialogProps["maxWidth"];
  loading?: boolean;
  fullWidth?: boolean;
  bgColor?: string;
};

export interface StylesProps {
  background: string;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(30px)",
    opacity: ".85 !important"
  },
  paper: props => ({
    // borderStyle: "solid",
    // borderColor: "#303E70",
    borderRadius: "0", 
    // borderWidth: "2px",
    // background: props.background || "#FFF",
    background: '#FFF',
    // background: materialStatusColours[props.background]?.bg || "#FFF",
    opactiy: "1 !important",
    // backgroundImage: `linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))`,
    // backgroundImage: `linear-gradient(90deg, #FFF, ${props.background}, #FFF)`
    // backgroundImage: `linear-gradient(90deg, ${props.background}, #FFF, ${props.background})`
  }),
  content: {
    display: "flex",
    // padding: "0px 10px"
    padding: 0,
  },
  title: {
    // textAlign: "center",
    fontSize: "1em",
    fontWeight: 700,
    // background: "#FFF"
  },
  close: {
    fontSize: "0.80em",
    color: "red",
    cursor: "pointer"
  },
  button: {
    display: "flex",
    // flexGrow: 1,
    fontSize: "12px",
    color: "white",
    background: " #005eff",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: 600,
    // maxWidth: "40%",
    // height: "33px",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#1a6fef",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  }
}));

const GenericPopup = ({ isOpen, onClose, title, actions, children, maxWidth, fullWidth = false, loading = false, bgColor }: PropsWithChildren<IGenericPopup>) => {
  const paperPropOverride: StylesProps = { background: bgColor || "#FFF" }
  const classes = useStyles(paperPropOverride);
  /* const { isOpen: openState } = useModal({ isOpen, closeOnOverlayClick: true }); */
  return (<Dialog
    open={isOpen}
    onClose={onClose}
    BackdropProps={{ classes: { root: classes.backDrop } }}
    PaperProps={{ classes: { root: classes.paper } }}
    maxWidth={maxWidth || "md"}
    scroll="paper"
    fullWidth={fullWidth}
  >
    {/* <div style={{padding: "0.5rem", backgroundColor: bgColor}}> */}
    {/* <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", background: "#FFF", borderBottom: "0.25px solid black", padding: "10px 20px"}}> */}
    {title ? <DialogTitle disableTypography className={classes.title}>{title}️</DialogTitle> : null}
    {/* <span className={classes.title}>{title}</span> */}
    {/* <span className={classes.close} onClick={onClose}>Close</span> */}
    {/* </div> */}
    {loading ?
      // <LoadingSpinner /> 
      <div style={{ padding: '0em 1.25em' }}><LoadingTowers title={"Fetching details"} /></div>
      :
      <>
        <DialogContent
          classes={{ root: classes.content }}
          style={{ paddingTop: 0 }}
        >
          {children}
        </DialogContent>
        {actions.length ?
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              // flexGrow: 1,
              justifyContent: "end",
              alignContent: "center",
              marginBottom: '1em',
              padding: '0em 1em'
            }}
          >
            {
              actions.map(({ type, onClick, disabled = false }) => (
                <Button
                  key={type}
                  variant="contained"
                  disabled={disabled}
                  color="primary"
                  size="small"
                  // style={{ marginLeft: ".5em", marginRight: ".5em" }}
                  className={classes.button}
                  onClick={() => { onClick(); }}
                >
                  {type}
                </Button>
              ))
            }
          </DialogActions>
          : null
        }
      </>
    }
    {/* </div> */}
  </Dialog>);
};

export default memo(GenericPopup);
