import React, { memo, Dispatch, ReducerAction, useState } from 'react';
import { TextField, TextareaAutosize } from '@material-ui/core';
import { TextInput, Textarea, NumberInput } from '@mantine/core';
import RichTextGeneric from '../richText/RichTextDescInput';
import RichTextGenericOutput from '../richText/RichTextGenericOutput';

export interface IGenericInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: any;
  type: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  description?: string;
  size?: string;
  icon?: JSX.Element;
  coreField?: boolean;
  titleStyleException?: boolean;
  debounce?: boolean;
  params?: {[K: string] : any} | null | undefined
};

const parseValue = (val: string) => val.replace(/[,]+/g,"") 
const formatValue = (value: string, type: string) => (type === 'decimal' ? value.length ? new Intl.NumberFormat('en-IN').format(Number.parseFloat(value)) : value : value)

const GenericInput = ({ field, dispatch, state, type, label, disabled, required, description, size, icon, coreField = false, titleStyleException = false, debounce = false }: IGenericInputProps) => {
  const [val, setVal] = useState(state || "");
  return field == "title" && titleStyleException ?
    <TextInput
      value={debounce ? val : (state || "")}
      disabled={!!disabled}
      onChange={e => {
        const input = e.target.value;
        if (debounce) { setVal(() => input); }
        else if (!debounce) {
          dispatch({ type: 'REPLACE_GENERIC', payload: { input, type, field } });
        }
      }}
      onBlur={e => { if (debounce) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: val, type, field } }); } }}
      style={{ width: '100%' }}
      sx={{
        ["& .mantine-InputWrapper-wrapper"]: { width: '100%' },
        ["& .mantine-TextInput-root"]: { width: '100%' },
        ["& .mantine-TextInput-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
        ["& .mantine-TextInput-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', border: 0, padding: 0, fontSize: "1em", fontWeight: 700, '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: 0 } },
        ["& .mantine-TextInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' }
      }}
    />
    : type === "multi-line-text" ?  disabled ? (<RichTextGenericOutput content={state} label={label} /> ) : (
      <RichTextGeneric 
        label={label} 
        field={field}
        state={state}
        type={type}
        disabled={disabled}
        dispatch={dispatch}
        description={''}
        bubbleToolbar={true}
        required={required || false}
        size={size || 'sm'}
        icon={icon}
        placeholder={label}
      />
    )
      : type === "text" ? (
        <Textarea
        label={label}
        value={debounce ? val : (state || "")}
        disabled={!!disabled}
        placeholder={label}
        sx={{
          ["& .mantine-Textarea-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-Textarea-input"]: {  marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', border: '0.0525em solid #E9E9E9', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: 0 } },
          ["& .mantine-TextInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' }
        }}
        autosize
        // minRows={2}
        onChange={e => {
          const input = e.target.value;
          if (debounce) { setVal(() => input); }
          if (!debounce) {
            dispatch({ type: 'REPLACE_GENERIC', payload: { input, type, field } });
          }
        }}
        onBlur={e => { if (debounce) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: val, type, field } }); } }}
        required={required || false}
        size={size || 'sm'}
        icon={icon}
      />
      ) : (
        <NumberInput
          label={label}
          // type={type === 'decimal' ? 'text' : undefined}
          stepHoldDelay={500}
          stepHoldInterval={100}
          value={typeof state === "string" ? !state.length ? '' : Number.parseFloat(state) : state}
          disabled={!!disabled}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(value) => dispatch({ type: 'REPLACE_GENERIC', payload: { input: value, type, field } })}
          required={required || false}
          sx={{ ["& .mantine-NumberInput-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600,  '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' } },
          ["& .mantine-NumberInput-input"]: {  marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em' }
        }}
          icon={icon}
          parser={parseValue}
          hideControls
          formatter={(value) => formatValue(value, type)}
        />
      );
};

export default memo(GenericInput);
