import { types, flow, getRoot, Instance, getEnv } from 'mobx-state-tree';
import { getUser } from "../api/Auth";
import jwt_decode from "jwt-decode";
import Cookie from '../configs/cookie';
import { LateStoreModel, ILateStoreModel } from './DataStore';
import axios from 'axios';
import config from '../configs/clientConfig';
// import { removeNotificationToken } from '../api/transactionServer';

// !removed fallback jwt
// const __fallback_jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlJUX0hPXzAxMyIsImVtYWlsIjoicGFsbGF2aS5tQGxhcnNlbnRvdWJyby5jb20iLCJ1c2VybmFtZSI6IlBhbGxhdmkiLCJjdXN0b21lcl9pZCI6Imx0ciIsImN1c3RvbWVyX25hbWUiOiJMJlQgUmVhbHR5IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImVkaXRvciIsInVzZXIiLCJtb2QiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoidXNlciIsIngtaGFzdXJhLXVzZXItaWQiOiJSVF9IT18wMTMiLCJ4LWhhc3VyYS1jdXN0b21lci1pZCI6Imx0ciJ9LCJpYXQiOjE1NzEyNDUxMjUsImV4cCI6MTU3MjQ1NDcyNX0.oq7PWx1_HPk1fnvrh58rBiBn5zMxRr1WV1IaCBjV7II";

export const Auth = types.model({
    isAuthenticated: false,
    error: types.maybeNull(types.string),
    jwtToken: '',
    username: types.maybeNull(types.string),
}).volatile(_ => ({ loading: false })).views(self => ({
    get userId(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).id : ''; },
    get userName(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).username : ''; },
    get customerName(): string { return self.isAuthenticated ? (jwt_decode(self.jwtToken).customer_name || '') : ''; },
    get customerId(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).customer_id : ''; },
    get email(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).email : ''; },
    get token(): string { return self.isAuthenticated ? self.jwtToken : '' },
    get expiry() { return self.isAuthenticated ? jwt_decode(self.jwtToken).exp : null; }
})).actions((self) => ({
    setError() { self.error = null },
}))
    .actions(self => ({
        auth: flow(function*(userName: string, password: string) {
            self.username = userName;
            self.loading = true;
            try {
                // refreshProjects may need to be called here.
                const data: string = yield getUser(userName, password);
                if (!data) throw new Error("Give me valid token");
                self.jwtToken = data
                self.isAuthenticated = true;
                localStorage.setItem('Authorization', data);
                getEnv(self).mixpanel.identify(self.userId);
                getEnv(self).mixpanel.people.set_once({
                    'customerId': self.customerId,
                    'env': config.environment,
                    'email': self.email,
                    'name': self.userName
                });
                getEnv(self).mixpanel.register({ userName, customerId: self.customerId, customerName: self.customerName, email: self.email });

            }
            catch (err) {

                self.error = err.message;
                setTimeout(() => {
                    self.setError()
                }, 5000);
            }
            finally {
                self.loading = false;
            }
        }),
        getCustomer() {
            return self.isAuthenticated ? (jwt_decode(self.jwtToken).customer_name || '') : '';
        },
        setCookieToken() {
            self.loading = true;
            try {
                const jwtAuth = Cookie.get("jwtAuth");
                if (jwtAuth) {
                    if (jwtAuth !== localStorage.getItem('Authorization')) {
                        self.jwtToken = jwtAuth;
                        self.isAuthenticated = true;
                        (getRoot(self) as Instance<typeof LateStoreModel.TYPE>).refreshProjects();
                        localStorage.setItem('Authorization', jwtAuth);
                        Cookie.erase("jwtAuth");
                    }
                }
            } catch (err) {
                self.error = err.message;
                setTimeout(() => {
                    self.setError()
                }, 5000);
            }
            finally {
                self.loading = false;
            }
        },
        setLoading(arg: boolean) { self.loading = arg; },
        forgetPassword(email: string) {
            self.loading = true;
            try {
                const res = axios.post(config.RestApiUrl + 'auth/forgotPassword', { email })
                return res
            }
            catch (err) {
                self.error = err.message;
                setTimeout(() => {
                    self.setError()
                }, 5000);
            }
            finally {
                self.loading = false;
            }
        },
        login() { self.isAuthenticated = true; },
        logout() {
            const token = (getRoot(self) as ILateStoreModel).notifications.token;
            // if (token) {
            //     removeNotificationToken({ customerId: self.customerId, user: self.userId, token });
            // }
            localStorage.removeItem('notificationToken');
            Cookie.erase("jwtAuth");
            Cookie.erase("projectsInfo");
            self.isAuthenticated = false; localStorage.clear(); self.jwtToken = '';
            getEnv(self).mixpanel.reset();
        }
    }))

export type IAuthModel = typeof Auth.Type;
