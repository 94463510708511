import { ActionIcon, Flex, Slider, TextInput, Tooltip } from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';
import { IconDeviceFloppy, IconEdit } from '@tabler/icons-react';
import { MRT_Column, MRT_ColumnDef, MRT_SortingFn, MRT_TableOptions } from 'mantine-react-table';
import { observer } from 'mobx-react-lite';
import { getSnapshot, Instance } from 'mobx-state-tree';
import React, { useMemo } from 'react';
import ActivityStatus from '../../components/requests/cells/ActivityStatus';
import DateCell from '../../components/requests/cells/Date';
import { LateStoreModel } from '../../models/DataStore';
import { IUnitActivity } from '../../models/Planning';
import { activityStatuses } from '../../utils/constants';
import { sortingStrategyPosition } from '../../utils/utils';
import moment from 'moment';

export interface BaseUnitActivityDetails {
  id: string;
  title: string;
  statusId: string;
  statusName: string;
  blockName: string;
  activityTypeTitle: string;
  progress: number;
  planStart?: string;
  planEnd?: string;
  plannedTotalQuantity?: number
};

export interface BaseUnitDetails {
  id: string;
  name: string;
  unitType: string;
  block: string;
  floor: string;
};

export interface UnitActivityDetails extends BaseUnitActivityDetails {
  [K: string]: any;
};

export interface UnitDetails extends BaseUnitDetails {
  [K: string]: any;
};

const dateAccessorFn = (key) => (row) => {
  if (!row[key]) { return null; }
  const sDay = new Date(row[key]);
  return sDay;
};

const __floorNumSort = sortingStrategyPosition("floorIdx", "floorLabel");
const floorSortFn: MRT_SortingFn<any> = (rowA, rowB, _) => __floorNumSort(rowA.original, rowB.original);

const CustomHeader = ({ column }) => <em>{column.columnDef.header}</em>;

const EditSlider = observer(({ column: { id }, unitActivity }: { column: MRT_Column<UnitActivityDetails>; unitActivity?: IUnitActivity; }) => (
  <Slider
    marks={
      [
        { value: 0, label: '0%' },
        { value: 100, label: '100%' },
      ]}
    value={unitActivity ? (unitActivity[id] || 0) : 0}
    onChangeEnd={val => { unitActivity?.edit("progress", val); }}
  />
)
);

const ObservableDisabledTextInput = observer(({ column: { id }, unitActivity }) => (
  <TextInput
    disabled
    value={unitActivity ? unitActivity[id] || "" : ""}
  />
));
//TODO: Add an activity sort by precedence function
const useUnitTableColumnsDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<UnitDetails>[] = (store) => useMemo(() => [
  {
    accessorKey: 'blockName',
    header: 'Block',
    size: 40,
    enableClickToCopy: false,
    enableColumnFilterModes: false,
    editVariant: 'select',
    mantineEditSelectProps: ({ row, column, table }) => ({
      disabled: !(row.id === table.getState().creatingRow?.id),
      data: store.planning.dropdowns.get("blocks")?.options.map(({ id, name }) => ({ label: name, value: id })) || [],
      onChange: (val) => { store.planning.newUnit?.edit("blockId", val, true) },
      value: store.planning.newUnit?.blockId,
    }),
  },
  {
    accessorKey: 'unitTypeName',
    enableClickToCopy: false,
    header: 'Location Type',
    size: 80,
    editVariant: 'select',
    enableColumnFilterModes: false,
    mantineEditSelectProps: ({ row, column, table }) => ({
      disabled: !(row.id === table.getState().creatingRow?.id),
      data: store.planning.dropdowns.get("unitTypes")?.options.map(({ id, name }) => ({ label: name, value: id })) || [],
      onChange: (val) => { store.planning.newUnit?.edit("unitTypeId", val, true) },
      value: store.planning.newUnit?.unitTypeId,
    }),
  },
  {
    accessorKey: 'floorLabel',
    header: 'Floor',
    size: 40,
    editVariant: 'select',
    enableColumnFilterModes: false,
    sortingFn: floorSortFn,
    mantineEditSelectProps: ({ row, column, table }) => ({
      disabled: !((row.id === table.getState().creatingRow?.id) && !!store.planning.newUnit?.blockId),
      data: store.planning.newUnit?.floors.map(({ id, name }) => ({ label: name, value: id })) || [],
      onChange: (val) => { store.planning.newUnit?.edit("floorIdx", val, true) },
      value: store.planning.newUnit?.floorIdx,
    }),
  },
  {
    accessorKey: 'name',
    header: 'Title',
    size: 300,
    enableClickToCopy: false,
    enableColumnFilterModes: false,
    filterVariant: 'text',
    enableSorting: false,
    editVariant: 'text',
    mantineEditTextInputProps: () => ({
      onBlur: (event) => { store.planning.newUnit?.edit("name", event.target.value); },
    }),
  },
], [store.planning.newUnit?.floors, store.planning.newUnit?.blockId]);

const useUAColumnsDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<UnitActivityDetails>[] = (store) => useMemo<MRT_ColumnDef<UnitActivityDetails>[]>(() =>
  [
    {
      accessorKey: 'blockName',
      header: 'Block',
      size: 40,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      /* enableEditing: false, */
      Edit: ({ column }) => <ObservableDisabledTextInput column={column} unitActivity={store.planning.currentlyEditing} />,
    },
    {
      accessorKey: 'unitTitle',
      header: 'Location',
      size: 300,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      editVariant: 'select',
      mantineEditSelectProps: ({ row, column, table }) => ({
        disabled: !(row.id === table.getState().creatingRow?.id),
        data: store.planning.dropdowns.get("units")?.options.map(({ id, name }) => ({ label: name, value: id })) || [],
        onChange: (val) => { store.planning.currentlyEditing?.edit("unitId", val, true) },
        value: store.planning.currentlyEditing?.unitId,
      }),
    },
    {
      accessorKey: 'activityTypeTitle',
      enableClickToCopy: false,
      header: 'Activity Type',
      size: 100,
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
      editVariant: 'select',
      mantineEditSelectProps: ({ row, column, table }) => ({
        disabled: !(row.id === table.getState().creatingRow?.id),
        data: store.planning.dropdowns.get("activityTypes")?.options.map(({ id, name }) => ({ label: name, value: id })) || [],
        onChange: (val) => { store.planning.currentlyEditing?.edit("activityTypeId", val, true) },
        value: store.planning.currentlyEditing?.activityTypeId,
      }),
    },
    {
      accessorKey: 'title',
      header: 'Title Suffix',
      size: 300,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      editVariant: 'text',
      mantineEditTextInputProps: () => ({
        placeholder: 'Title Suffix (optional)',
        onBlur: (event) => { store.planning.currentlyEditing?.edit("title", event.target.value); },
      }),
    },
    {
      accessorKey: 'statusName',
      enableClickToCopy: false,
      header: 'Status',
      size: 50,
      filterVariant: 'multi-select',
      editVariant: 'select',
      enableColumnFilterModes: false,
      Cell: ({ row }) => <ActivityStatus row={row.original.unitActivity} />,
      mantineEditSelectProps: ({ row, column, table }) => ({
        disabled: true,
        data: Object.keys(activityStatuses).map(value => ({ value, label: activityStatuses[value] })),
        value: store.planning.currentlyEditing?.statusId,
      }),

    },
    {
      accessorFn: dateAccessorFn('planStart'),
      id: 'planStart',
      header: 'Planned Start Date',
      filterVariant: 'date-range',
      sortingFn: 'datetime',
      enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
      Cell: DateCell,
      Edit: () => <DateTimePicker
        placeholder='Pick a Start date (optional)'
        translate="Pick a date and time"
        onChange={(val) => {
          store.planning.currentlyEditing?.edit("planStart", val ? moment(val).startOf('day').toISOString() : undefined );
        }}
        value={store.planning.currentlyEditing?.planStart ? moment(store.planning.currentlyEditing?.planStart).utc().startOf('day').toDate() : undefined}
        size="sm"
        sx={{
          ["& .mantine-DateTimePicker-label"]: { marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600, opactity: 0.8 },
          ["& .mantine-DateTimePicker-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
          ["& .mantine-DateTimePickerInput-icon"]: { justifyContent: 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
      />,
      Header: CustomHeader,      //custom header markup
    },
    {
      accessorFn: dateAccessorFn('planEnd'),
      id: 'planEnd',
      header: 'Planned End Date',
      filterVariant: 'date-range',
      sortingFn: 'datetime',
      enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
      Cell: DateCell,
      Edit: () => <DateTimePicker
        placeholder='Pick an End date (optional)'
        translate="Pick a date and time"
        onChange={(val) => {
          // store.planning.currentlyEditing?.edit("planEnd", val?.toISOString());
          store.planning.currentlyEditing?.edit("planEnd", val ? moment(val).endOf('day').toISOString() : undefined );
        }}
        value={store.planning.currentlyEditing?.planEnd ? moment(store.planning.currentlyEditing?.planEnd).utc().endOf('day').toDate() : undefined}
        size="sm"
        sx={{
          ["& .mantine-DateTimePicker-label"]: { marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600, opactity: 0.8 },
          ["& .mantine-DateTimePicker-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
          ["& .mantine-DateTimePickerInput-icon"]: { justifyContent: 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
      />,
      Header: CustomHeader, //custom header markup
    },
    // {
    //   accessorKey: 'progress',
    //   enableClickToCopy: false,
    //   header: 'Progress',
    //   size: 50,
    //   filterVariant: 'range-slider',
    //   enableColumnFilterModes: false,
    //   /* editVariant: "text",
    //    * mantineEditTextInputProps: {
    //    *   onBlur: (event) => { store.planning.currentlyEditing?.edit("progress", event.target.value); },
    //    * }, */
    //   Edit: ({ column }) => <EditSlider column={column} unitActivity={store.planning.currentlyEditing} />,
    // },
    {
      accessorKey: 'plannedTotalQuantity',
      id: 'plannedTotalQuantity',
      header: 'Planned Total QTY',
      size: 200,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      filterVariant: 'range-slider',
      editVariant: 'text',
      mantineEditTextInputProps: () => ({
        onBlur: (event) => { 
          // console.log('on blurr',event)
          store.planning.currentlyEditing?.edit("plannedTotalQuantity", event.target.value ? Number.parseInt(event.target.value) : Number.parseInt(event.target.value) === Number.NaN ? null  : null); },
      }),
    },
    {
      accessorKey: 'unitTypeName',
      enableClickToCopy: false,
      header: 'Location Type',
      size: 80,
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
      /* enableEditing: false, */
      Edit: ({ column }) => <ObservableDisabledTextInput column={column} unitActivity={store.planning.currentlyEditing} />,
    },
  ]
  , []);

export const useColumnsDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<any>[] = store => {
  const unitColumnsDef = useUnitTableColumnsDef(store);
  const uAColumnsDef = useUAColumnsDef(store);
  const columnsDef = useMemo(() => store.planning.currentView === "units" ? unitColumnsDef : uAColumnsDef, [store.planning.currentView, store.planning.newUnit?.blockId, store.planning.newUnit?.floors]);
  return columnsDef;
};
export const useTableOptions: (store: Instance<typeof LateStoreModel>) => Partial<MRT_TableOptions<any>> = store => useMemo(() => ({
  creatingMode: 'row',
  editingMode: 'row',
  enableEditing: true,
  onCreatingRowCancel: ({ table }) => { store.planning.setMode("read", {}); if (store.planning.currentView === "units") { table.setColumnVisibility({ 'mrt-row-actions': false }); } },
  onCreatingRowSave: ({ table, exitCreatingMode, values }) => {
    if (store.planning.currentView === "units") {
      store.planning.createUnit();
      table.setColumnVisibility({ 'mrt-row-actions': false });
    }
    else {
      store.planning.createUnitActivity();
      /*TODO: Fix below error soon:
      Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. Error Component Stack
      File: Collapse.tsx (mantine file)
      */
    }
    exitCreatingMode();
  },
  onEditingRowCancel: () => { store.planning.setMode("read", { action: "cancel" }); },
  onEditingRowSave: ({ exitEditingMode }) => { store.planning.setMode("read", { action: "save" }); exitEditingMode(); },
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: 'Edit', //change "Actions" to "Edit"
      //use a text button instead of a icon button
      /* Cell: ({ row, table }) => (
       *   <button onClick={() => table.setEditingRow(row)}>Edit Customer</button>
       * ), */
    },
  },
  renderRowActions: ({ row, table }) => (
    <Flex gap="md">
      <Tooltip label="Edit">
        <ActionIcon onClick={() => { table.setEditingRow(row); store.planning.setMode("edit", { id: row.original.id }) }}>
          <IconEdit />
        </ActionIcon>
      </Tooltip>
    </Flex>
  ),
  positionActionsColumn: 'last',
  enablePagination: true,
  paginationDisplayMode: "pages",
  positionPagination: "bottom",
  autoResetPageIndex: false,
  initialState: {
    pagination: { pageSize: 100, pageIndex: 0, },
    showColumnFilters: true, density: 'xs',
    columnVisibility: { 'mrt-row-actions': false },
  },
  mantinePaperProps: () => ({
    sx: {
      padding: '0em 1em',
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }
  }),
})
  , [store.planning.currentView]);

export const gridKeys = [
  "id",
  "blockId",
  "blockName",
  "title",
  "unitId",
  "unitTitle",
  "activityTypeId",
  "activityTypeTitle",
  "planStart",
  "planEnd",
  "statusId",
  "statusName",
  "canEdit",
  "unitTypeId",
  "unitTypeName",
  // "progress",
  "plannedTotalQuantity"
];
