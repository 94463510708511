const config = {
    SiteUrl: process.env.REACT_APP_SITE_URL,
    RestApiUrl: process.env.REACT_APP_REST_API_URL,
    ImageUrl: process.env.REACT_APP_API_URL,
    PortalV1: process.env.REACT_APP_PORTAL_V1_URL,
    LogRocketKey: process.env.REACT_APP_LOGROCKET_KEY,
    environment: process.env.REACT_APP_ENV,
    baseURL: process.env.REACT_APP_IMAGE_BASE_URL,
    TransactionServerUrl: process.env.REACT_APP_TRANSACTION_SERVER_URL,
    isWhatsappEnabled: process.env.REACT_APP_WHATSAPP_FLAG === "true",
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    // firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // firebaseSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    // firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
    // novuSubscriberId: process.env.REACT_APP_NOVU_SUBSCRIBER_ID,
    novuAppId: process.env.REACT_APP_NOVU_APP_ID,
    language: process.env.REACT_APP_LANGUAGE,
};

module.exports = config;
